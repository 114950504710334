.container_newplan {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: rgba(26, 28, 30, 0.3);
  backdrop-filter: blur(6.5px);
}

.formMultiSteps {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 24px -1px rgba(140, 148, 167, 0.24);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.container_createPlanTrading {
  position: relative;
  width: 800px;
  height: 70vh;
  margin: 0 auto 0 auto;
  top: 120px;
  background: #ffffff;
  box-shadow: 0px 4px 24px -1px rgba(140, 148, 167, 0.24);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.link_dashboard {
  position: absolute;
  bottom: -6%;
  right: -1%;
}
