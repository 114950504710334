.custom-link {
  text-decoration: none;
  color: inherit;
}

.sidebar {
  width: 220px;
  max-width: 220px;
  height: 100vh;
  background-color: #31b099;
  color: #ffffff;
}

.sidebar-collapsed {
  width: 88px !important;
  height: 100vh;
  background-color: #31b099;
}
