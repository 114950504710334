.container_register {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.background_img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.container_formregister {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 300px;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    107.27deg,
    #ffffff 56.05%,
    rgba(217, 217, 217, 0.1) 100%
  );
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.textField {
  width: 85%;
  left: 30px;
}

.button_register {
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 90%;
  height: 30px;
  left: 5%;
  top: 5%;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  color: #ffffff;
  background: #1d9d8b;
  border-radius: 20px;
}

.container_steps {
  position: absolute;
  width: 200px;
  bottom: 15px;
  margin: 0 20px 0 115px;
}

.icons_progress {
  position: absolute;
  width: 420px;
  height: 40px;
  bottom: 5px;
}

.Button_planready {
  position: relative;
  display: flex;
  top: 0px;
  right: 13px;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  font-weight: 600;
  background-color: #ffffff;
}

.closed_form {
  position: absolute;
  top: 0%;
  right: 0%;
  color: #9033ff;
}

.phoneInput {
  position: relative;
  width: 70%;
}
