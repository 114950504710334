.container_recovery {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.background_img {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.container_title {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 411px;
  height: 90px;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -65%);
}

.container_formrecovery {
  position: absolute;
  width: 420px;
  height: 256px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background: linear-gradient(
    107.27deg,
    #ffffff -11.03%,
    rgba(217, 217, 217, 0.1) 100%
  );
  /* Blur Background */

  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
  z-index: 2;
}

.button_recovery {
  position: relative;
  width: 90%;
  height: 30px;
  left: 5%;
  top: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  color: #ffffff;
  background: #1d9d8b;
  border-radius: 20px;
}
