.container_login {
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  background-color: #ffffff;
}

.side_login {
  position: relative;
  background: linear-gradient(
    113.64deg,
    #9dddd4 1.37%,
    #1d9d8b 54.28%,
    #145046 100%
  );
}

.container_formlogin {
  position: relative;
  width: 450px;
  height: 240px;
  background: #ffffff;
  box-shadow: 0px 12px 25px -5px rgba(179, 182, 186, 0.3);
  border-radius: 20px;
}

.textField_login {
  width: 90%;
}

.button_login {
  position: relative;
  width: 90%;
  height: 40px;
  top: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  color: #ffffff;
  background: #1d9d8b;
  border-radius: 20px;
  border-color: #ffffff;
}

.button_linkregister {
  position: relative;
  margin-top: 20px;
  width: 350px;
  background: #55ba07;
  border-color: #ffffff;
  border-radius: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  color: #ffffff;
}
