.menuUser {
  position: absolute;
  left: 1234px;
  top: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(140, 148, 167, 0.24);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.custom-link {
  text-decoration: none;
  color: inherit;
}
