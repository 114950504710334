.box_steps {
  position: absolute;
  width: 50%;
  bottom: 1%;
  margin: 0 20px 0 200px;
}

.box_iconsprogress {
  position: absolute;
  width: 100%;
  height: 70px;
  bottom: -3%;
}

.Button_planready {
  position: relative;
  top: 15px;
  right: 10px;
  width: 180px;
  height: 36px;
  background: #9033ff;
  border-radius: 30px;
  color: #fefbf8;
  font-weight: 600;
}

.closed_form {
  position: absolute;
  top: 0%;
  right: 0%;
  color: #9033ff;
}

/* CSS STEPS */

.box_principal {
  position: relative;
  height: 95%;
  margin: 10px 10px 10px 10px;
}

.box_firts {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 90%;
  height: 50%;
  background: #ffffff;
  border-radius: 16px;
}

.containerSteps {
  position: relative;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
}
.container_gestion {
  display: grid;
  grid-template-columns: 350px 250px;
  grid-template-rows: 200px 200px;
  grid-template-areas:
    "gestion resumen"
    "gestion trades";
}

.containerDate {
  position: relative;
  margin: 10% auto 0 auto;
  width: 98%;
  height: 100px;
  background: rgba(22, 127, 112, 0.11);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
}

.container_riesgo {
  display: grid;
  grid-area: gestion;
  width: 400px;
  height: 400px;
  background: rgba(22, 127, 112, 0.11);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
}

.table_resumen--createplan02 {
  position: relative;
  display: grid;
  grid-area: resumen;
  width: 300px;
  left: 430px;
  top: 40px;
}

.table_resumen--createplan02 td {
  width: 80px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #272727;
  border-bottom: 0.5px solid rgba(179, 182, 186, 0.22);
  border-collapse: collapse;
}

.table_resumen--createplan02 td:nth-child(3n + 1) {
  text-align: left;
  width: 140px;
}

.container_tradesxdays {
  position: relative;
  display: grid;
  grid-area: trades;
  top: 10%;
  width: 300px;
  left: 85px;
  background: #ffffff;
  box-shadow: 0px 4px 24px -1px rgba(140, 148, 167, 0.24);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.container_tradesxdays table {
  position: absolute;
  width: 210px;
  height: 110px;
  left: 80px;
  top: 51px;
}

.container_tradesxdays td {
  width: 50px;
  height: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #272727;
  border-bottom: 1px solid rgba(179, 182, 186, 0.22);
}

.container_tradesxdays td:nth-child(3n + 1) {
  width: 210px;
  text-align: center;
}

.container_mercadosForm {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  margin-left: 18px;
  grid-template-columns: 25% 25% 50%;
  grid-template-rows: 100%;
}

.container_piechartForm {
  position: relative;
  top: 80px;
  width: 300px;
  height: 100%;
}
