.custom-link {
  text-decoration: none;
  color: inherit;
}

.perfilUser {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  gap: 12px;
  width: 28.5%;
  max-width: 350px;
  min-width: 350px;
  height: 250px;
  left: 50px;
  top: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(140, 148, 167, 0.24);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.perfilUser__details {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 32px;
  width: 58%;
  height: 750px;
  left: 90px;
  top: 30px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 4px 24px rgba(140, 148, 167, 0.24);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.container_button {
  display: flex;
  flex-direction: column;
  gap: 20px;
  
}

.container_buttonUserMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 20px;
  width: 300px;
  height: 50px;
  color: #1A1C1E;
  background-color: transparent;
  border-color: #fff;
  border-radius: 20px;
}

.container_buttonUserMenuSelect {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 20px;
  width: 300px;
  height: 50px;
  color: #fff;
  background-color: #31b099;
  border-color: #fff;
  border-radius: 20px;
}


.container_buttonUserMenu:hover {
  color: #ffffff;
  background: #31b099;
  border-radius: 20px;
  border-color: #fff ;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
}

.container_buttonUserMenuSelect:hover {
  background-color: #1A1C1E;
}

.container_buttonUserMenu--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 35px;
  background-color: #EDF1F3;
  border-radius: 50px;
  box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.09);
}

.container_buttonUserMenu--iconSelect {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 35px;
  color: #31b099;
  background-color: #EDF1F3;
  border-radius: 50px;
  box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.09);
}
